// import logo from './logo.svg';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Home from '../Components/Home';
import Timer from '../Components/Timer';
import Pomodoro from '../Components/Pomodoro';
import NotFound from '../Components/NotFound';
import DropdownBelow from './DropdownBelow';
import Checkin from '../Components/Checkin';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ReactModal from 'react-modal';
import {EmailIcon,FacebookIcon,LinkedinIcon,PinterestIcon,RedditIcon,TelegramIcon, TwitterIcon, WhatsappIcon} from "react-share";
import {EmailShareButton, FacebookShareButton, LinkedinShareButton, PinterestShareButton, RedditShareButton, TelegramShareButton, TwitterShareButton,WhatsappShareButton} from "react-share";
import fullscreenLight from "../Resources/fullscreenLight.svg";
import fullscreenDark from "../Resources/fullscreenDark.svg";
import fullscreenCloseLight from "../Resources/fullscreenCloseLight.svg";
import fullscreenCloseDark from "../Resources/fullscreenCloseDark.svg";
import colorLight from "../Resources/colorLight.svg";
import colorDark from "../Resources/colorDark.svg";
import shareLight from "../Resources/shareLight.svg";
import shareDark from "../Resources/shareDark.svg";

// Different types for signedin
import ToolbarSignedin from '../Components/ToolbarSignedin';

import SidebarRight from '../Components/SidebarRight';
import '../App.css';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';

let audio = document.getElementById("myAudio"); 
audio.volume = 0.8;

function PomodoroSignedin({ mode, handleModeChange }) {

  const modeClassNames = {
    dark: 'dark',
    neon: 'neon',
    darkBlue: 'darkBlue',
    darkRed: 'darkRed',
    light: 'light',
    tan: 'tan',
    lightBlue: 'lightBlue',
    lightRed: 'lightRed'
  };
  
  const currentMode = mode;
  const handle = useFullScreenHandle();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const shareUrl = "https://thinker.so";
  const title = 'Thinker';

  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setIsHidden(true);
    }, 5000);

    document.addEventListener('mousemove', () => {
      clearTimeout(timeoutId);
      setIsHidden(false);

      timeoutId = setTimeout(() => {
        setIsHidden(true);
      }, 5000);
    });

    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener('mousemove', () => {});
    };
  }, []);

  const handleClick = (newMode) => {
    handleModeChange(newMode);
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const selectedMode = modeClassNames[currentMode];
  const [fullScreen, setFullScreen] = React.useState(false);
  const toggleFullScreen = () => setFullScreen(!fullScreen);

  return (
    <div>
      <FullScreen handle={handle}>   
      <div className={`topnav-wrapper ${selectedMode}`}>
        <div className='logo-wrapper'>Pomoflip</div>
        <div className='spacer-nav'/>
        <div className="navi-wrapper">
        <DropdownBelow mode={selectedMode} icon={<div><Checkin/></div>} position="below">
          <div className="level-wrapper">
            <div className='level-header'>Your level:</div>
            <div className="spacer"/>
            <div className="level">
              <Checkin/>
            </div>
            <div className="level-description">
            <i>Come back tomorrow to level up!</i>
          </div>
          </div>
          
        </DropdownBelow>
        </div>
        <div className='spacer'/>
        <DropdownBelow mode={selectedMode} icon={<div><img src={selectedMode === "light" || selectedMode === "tan" || selectedMode === "lightBlue" || selectedMode === "lightRed"? colorLight : colorDark} className="icon-wrapper"/></div>} position="below">
          <div className="mode-buttons">
            <div className="mode-wrapper">
              <div className="dark-select" onClick={() => handleClick('dark')}> </div>
              <div className="spacer"/>
              <a href="https://www.thinker.so/upgrade" target="_parent" className="neon-select" >🔒</a>
              <div className="spacer"/>
              <a href="https://www.thinker.so/upgrade" target="_parent" className="darkBlue-select" >🔒</a>
              <div className="spacer"/>
              <a href="https://www.thinker.so/upgrade" target="_parent" className="darkRed-select" >🔒</a>
            </div>
            <div className="mode-wrapper">
              <div className="light-select" onClick={() => handleClick('light')}> </div>
              <div className="spacer"/>
              <a href="https://www.thinker.so/upgrade" target="_parent" className="tan-select">🔒</a>
              <div className="spacer"/>
              <a href="https://www.thinker.so/upgrade" target="_parent" className="lightBlue-select" >🔒</a>
              <div className="spacer"/>
              <a href="https://www.thinker.so/upgrade" target="_parent" className="lightRed-select" >🔒</a>
            </div>
          </div>
        </DropdownBelow>
        <div className='spacer-nav'/>
        <div className="navi-wrapper">
        <DropdownBelow mode={selectedMode} icon={<div><img src={selectedMode === "light" ||selectedMode === "tan" || selectedMode === "lightBlue" || selectedMode === "lightRed" ? shareLight : shareDark} className="icon-wrapper-small"/></div>} position="below" customHeight='125px' customWidth='280px'>
          <div className='share-wrapper'>
            <div className='level-header'>
              Share
            </div>
            <br/>
            <div>
              <EmailShareButton
                url={shareUrl}
                quote={title}>
                <EmailIcon size={32} round={true} />
              </EmailShareButton>
              <FacebookShareButton
                url={shareUrl}
                quote={title}
              >
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                quote={title}>
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>
              <PinterestShareButton
                url={shareUrl}
                quote={title}>
                <PinterestIcon size={32} round={true}/>
              </PinterestShareButton>

              <LinkedinShareButton
                url={shareUrl}
                quote={title}>
               <LinkedinIcon size={32} round={true} />
              </LinkedinShareButton>
              <RedditShareButton
                url={shareUrl}
                quote={title}>
                <RedditIcon size={32} round={true} />
              </RedditShareButton>
              <TelegramShareButton
                url={shareUrl}
                quote={title}>
                <TelegramIcon size={32} round={true} />
              </TelegramShareButton>
              <WhatsappShareButton
                url={shareUrl}
                quote={title}>
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>
              
            </div>
          </div>
          
        </DropdownBelow>
        </div>
        <div className='spacer'/>
        <div className="top-wrapper">
          {fullScreen ? (
          <div onClick={() => { handle.exit(); toggleFullScreen()}}>
            <img src={selectedMode === "light" ||selectedMode === "tan" || selectedMode === "lightBlue" || selectedMode === "lightRed"? fullscreenCloseLight : fullscreenCloseDark} className="icon-wrapper-small"/>
          </div>
          ) : (
          <div onClick={() => { handle.enter(); toggleFullScreen()}}>
            <img src={selectedMode === "light" ||selectedMode === "tan" || selectedMode === "lightBlue" || selectedMode === "lightRed"? fullscreenLight : fullscreenDark} className="icon-wrapper-small"/>
          </div>
          )}
        </div>
        
      </div>
      {/* Hides the toolbar */}
      <div className={`${isHidden ? 'hidden' : ''}`}>
        <ToolbarSignedin mode={selectedMode}/>
      </div>      
       {/* <ToolbarSignedin mode={selectedMode}/> */}
      <Pomodoro mode={selectedMode}/>
      </FullScreen>
    </div>
  );
}

export default PomodoroSignedin;
