import React, { useState } from 'react';
import './Toolbar.css';
import Leftsidebar from './Leftsidebar';
import TimeWidget from './TimeWidget';
import Spacebar from './Spacebar';
import spacesPopIcon from './spacespop.png'; // Update with the correct path
import musicPopIcon from './musicpop.png'; // Update with the correct path

function Toolbar({ mode, onUrlChange, isMuted, onMuteToggle, volume, onVolumeChange }) {
  const currentMode = localStorage.getItem('mode') || "light";
  const [timeWidgetType, setTimeWidgetType] = useState(null);
  const [spotifyUrl, setSpotifyUrl] = useState('https://open.spotify.com/embed/playlist/37i9dQZF1EQpesGsmIyqcW?utm_source=generator');
  const [userInput, setUserInput] = useState('');
  
  const handleTimeWidgetClose = () => {
    setTimeWidgetType(null);
  };

  const handleUserInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleSpotifyUrlSubmit = () => {
    const playlistId = userInput.split('playlist/')[1]?.split('?')[0];
    if (playlistId) {
      const newUrl = `https://open.spotify.com/embed/playlist/${playlistId}?utm_source=generator`;
      setSpotifyUrl(newUrl);
    } else {
      alert("Invalid Spotify URL");
    }
  };

  return (
    <div className={`toolbar-wrapper`}>
      <div className={`toolbar ${currentMode}`}>
        <Spacebar 
          icon={<img src={spacesPopIcon} alt="Spaces" className="toolbar-icon" />} 
          onUrlChange={onUrlChange} 
          initiallyOpen={true} 
          isMuted={isMuted} 
          onMuteToggle={onMuteToggle} 
          volume={volume} 
          onVolumeChange={onVolumeChange}
        >
          <div className="level-wrapper"></div>
        </Spacebar>

        <Leftsidebar icon={<img src={musicPopIcon} alt="Music" className="toolbar-icon" />}>
          <div className="level-wrapper">
            <h3>Custom Playlists</h3>
            <p>Add your favorite playlist from Spotify.</p>
            <div className='spotify-wrapper'>
              <input
                type="text"
                value={userInput}
                onChange={handleUserInputChange}
                placeholder="Enter Spotify playlist URL"
                className="playlist-input"
              />
              <button onClick={handleSpotifyUrlSubmit} className="playlist-button">Confirm</button>
              <iframe
                src={spotifyUrl}
                width="100%"
                height="375"
                frameBorder="0"
                allowFullScreen
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
                className="playlist-iframe"
              ></iframe>
            </div>
          </div>
        </Leftsidebar>

        <div className='linebreaker'/>

        <div className="time-controls">
          <button className='time-buttons' onClick={() => setTimeWidgetType('clock')}>
            <div className='icon'>⏰</div>
          </button>
          <button className='time-buttons' onClick={() => setTimeWidgetType('timer')}>
            <div className='icon'>⏳</div>
          </button>
          <button className='time-buttons' onClick={() => setTimeWidgetType('pomodoro')}>
            <div className='icon'>🍅</div>
          </button>
        </div>

        {timeWidgetType && <TimeWidget type={timeWidgetType} onClose={handleTimeWidgetClose} />}
      </div>
    </div>
  );
}

export default Toolbar;
