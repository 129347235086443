import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import spaceUrls from './spaceUrls.json';
import './Spacebar.css';
import MutedIcon from './Muted.svg'; // Update with the correct path
import UnmuteIcon from './Unmute.svg'; // Update with the correct path

function Spacebar({ icon, onUrlChange, children, mode, position, customLeft, customHeight = "100px", customWidth = "200px", initiallyOpen = false, isMuted, onMuteToggle, volume, onVolumeChange }) {
  const [showDiv, setShowDiv] = useState(initiallyOpen);
  const [searchQuery, setSearchQuery] = useState('');
  const ref = useRef(null);
  const navigate = useNavigate();

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowDiv(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredUrls = spaceUrls.filter(url =>
    url.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const shuffle = () => {
    const randomIndex = Math.floor(Math.random() * spaceUrls.length);
    const randomUrl = spaceUrls[randomIndex];
    onUrlChange(randomUrl.videoId);
    navigate(`/?id=${randomUrl.id}`);
  };

  const handleUrlChange = (videoId, id) => {
    onUrlChange(videoId);
    navigate(`/?id=${id}`);
  };

  const currentUrl = spaceUrls.find(url => url.videoId === onUrlChange);

  return (
    <div ref={ref} onClick={() => setShowDiv(!showDiv)} style={{ cursor: 'pointer' }}>
      {icon}
      <div
        onClick={e => e.stopPropagation()}
        className="selectMode"
        style={{
          width: customWidth,
          marginLeft: customLeft,
          backgroundColor: 'white',
          boxShadow: '0px 4px 4px rgba(206, 206, 206, 0.25)',
          display: showDiv ? 'block' : 'none',
          position: 'fixed',
          left: 10,
          top: 10,
          height: '97%',
          width: '300px',
          background: 'white',
          zIndex: 999,
          borderRadius: '10px',
          padding: '10px',
          gap: '5px'
        }}
      >
        <div>
          <h3>Select a space</h3>
          <div className='spacerSide'>
            {currentUrl ? currentUrl.name : 'Current space'}
            <div className="volume-controls">
              <button onClick={onMuteToggle} className="mute-button">
                <img src={isMuted ? MutedIcon : UnmuteIcon} alt={isMuted ? 'Muted' : 'Unmute'} />
              </button>
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={(e) => onVolumeChange(parseFloat(e.target.value))}
                className="volume-slider"
              />
            </div>
          </div>
          <button onClick={shuffle} className="shuffle-button">Shuffle</button>
          <input
            type="text"
            placeholder="Search space..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="playlist-input"
          />
        </div>

        <div className="urls-list">
          {filteredUrls.map((url) => (
            <button
              key={url.id}
              onClick={() => handleUrlChange(url.videoId, url.id)}
              className="url-button"
            >
              <img
                src={`https://img.youtube.com/vi/${url.videoId}/hqdefault.jpg`}
                alt={url.name}
                className="thumbnail"
                width={100}
              />
              <div className="video-title">{url.name}</div>
            </button>
          ))}
        </div>
        {children}
      </div>
    </div>
  );
}

export default Spacebar;
