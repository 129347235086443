import React from "react";
import ReactPlayer from 'react-player/lazy';
import './Player.css';

const Player = ({ videoId, isMuted, volume }) => {
  const url = `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&controls=0&origin=https%3A%2F%2Fv1.lifeat.io&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1`;

  return (
    <div className="video-background">
      <div className="gradient-overlay"></div>
      <ReactPlayer
        className='react-player'
        url={url}
        width='100vw'
        height='100vh'
        playing={true}
        muted={isMuted}
        loop={true}
        volume={volume}
      />
    </div>
  );
};

export default Player;
