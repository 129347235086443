import React, { useState, useRef, useEffect } from 'react';
import './Leftsidebar.css';


function DropdownBelow({ icon, children, mode, position, customLeft, customHeight="100px", customWidth="200px" }) {
  const [showDiv, setShowDiv] = useState(false);
  const ref = useRef(null);
  console.log("CustomLeft: " +customLeft)
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowDiv(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  return (
    <div ref={ref} onClick={() => setShowDiv(!showDiv)} style={{ cursor: 'pointer'}}>
      {icon}
      <div
        onClick={e => e.stopPropagation()}
        className ="selectMode"
        style={{
          backgroundColor: 'white',
          boxShadow: '0px 4px 4px rgba(206, 206, 206, 0.25)',
          display: showDiv ? 'block' : 'none',
          position: 'fixed',
          left: 10,
          top: 10,
          height: '97%',
          width: '300px',
          background: 'white',
          zIndex: 999,
          borderRadius: '10px',
        }}
      >
        {children}
      </div>
    </div>
  );  
}

export default DropdownBelow;
