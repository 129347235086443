import React, { useState, useEffect, useRef } from 'react';
import './TimeWidget.css';
import timerSound from './timerSound.m4a';
import RefreshIcon from "./Refresh.svg";
import SkipIcon from "./Skip.svg";

const TimeWidget = ({ type, onClose }) => {
  const [time, setTime] = useState(new Date());
  const [timer, setTimer] = useState(0);
  const [pomodoro, setPomodoro] = useState(25 * 60); // 25 minutes
  const [shortBreak, setShortBreak] = useState(5 * 60); // 5 minutes
  const [longBreak, setLongBreak] = useState(20 * 60); // 20 minutes
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [isPomodoroRunning, setIsPomodoroRunning] = useState(false);
  const [currentPhase, setCurrentPhase] = useState('pomodoro'); // 'pomodoro', 'shortBreak', 'longBreak'
  const [completedPomodoros, setCompletedPomodoros] = useState(0);
  const timerRef = useRef(null);
  const pomodoroRef = useRef(null);
  const audioRef = useRef(new Audio(timerSound));

  useEffect(() => {
    let interval;

    if (type === 'clock') {
      interval = setInterval(() => {
        setTime(new Date());
      }, 1000);
    } else if (type === 'timer' && isTimerRunning) {
      interval = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);
    } else if (type === 'pomodoro' && isPomodoroRunning) {
      interval = setInterval(() => {
        if (currentPhase === 'pomodoro') {
          setPomodoro((prev) => {
            if (prev > 0) return prev - 1;
            audioRef.current.play();
            setCompletedPomodoros((prev) => prev + 1);
            if (completedPomodoros > 0 && (completedPomodoros + 1) % 4 === 0) {
              setCurrentPhase('longBreak');
            } else {
              setCurrentPhase('shortBreak');
            }
            setPomodoro(25 * 60); // Reset for next Pomodoro
            return prev;
          });
        } else if (currentPhase === 'shortBreak') {
          setShortBreak((prev) => {
            if (prev > 0) return prev - 1;
            audioRef.current.play();
            setCurrentPhase('pomodoro');
            setShortBreak(5 * 60); // Reset for next Short Break
            return prev;
          });
        } else if (currentPhase === 'longBreak') {
          setLongBreak((prev) => {
            if (prev > 0) return prev - 1;
            audioRef.current.play();
            setCurrentPhase('pomodoro');
            setLongBreak(20 * 60); // Reset for next Long Break
            return prev;
          });
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [type, isTimerRunning, isPomodoroRunning, currentPhase, completedPomodoros]);

  const formatTime = (seconds) => {
    const m = Math.floor(seconds / 60);
    const s = seconds % 60;
    return `${m}:${s < 10 ? '0' : ''}${s}`;
  };

  const handleStartPauseTimer = () => {
    setIsTimerRunning((prev) => !prev);
    if (isPomodoroRunning) setIsPomodoroRunning(false); // Pause Pomodoro if running
  };

  const handleStartPausePomodoro = () => {
    setIsPomodoroRunning((prev) => !prev);
    if (isTimerRunning) setIsTimerRunning(false); // Pause Timer if running
  };

  const handlePhaseChange = (phase) => {
    if (isPomodoroRunning) return; // Prevent phase change while running
    setCurrentPhase(phase);
  };

  const handleNextPhase = () => {
    if (currentPhase === 'pomodoro') {
      setCompletedPomodoros((prev) => prev + 1);
      if ((completedPomodoros + 1) % 4 === 0) {
        setCurrentPhase('longBreak');
      } else {
        setCurrentPhase('shortBreak');
      }
      setPomodoro(25 * 60); // Reset for next Pomodoro
    } else if (currentPhase === 'shortBreak') {
      setCurrentPhase('pomodoro');
      setShortBreak(5 * 60); // Reset for next Short Break
    } else if (currentPhase === 'longBreak') {
      setCurrentPhase('pomodoro');
      setLongBreak(20 * 60); // Reset for next Long Break
    }
    setIsPomodoroRunning(false); // Pause Pomodoro when moving to next phase
  };

  const handleRefresh = () => {
    setTimer(0);
    setPomodoro(25 * 60);
    setShortBreak(5 * 60);
    setLongBreak(20 * 60);
    setIsTimerRunning(false);
    setIsPomodoroRunning(false);
    setCurrentPhase('pomodoro');
    setCompletedPomodoros(0);
  };

  const handleTimeInputChange = (e, setter) => {
    const value = e.target.value;
    const [minutes, seconds] = value.split(':').map(Number);
    setter((minutes * 60) + (seconds || 0));
  };

  const handleTimeClick = (ref) => {
    ref.current.focus();
  };

  return (
    <div className="time-widget">
      <button className="close-button" onClick={onClose}>✖</button>
      {type === 'clock' && <div className="time-display">{time.toLocaleTimeString()}</div>}
      {type === 'timer' && (
        <div className="timer-container">
          <input
            type="text"
            className="time-display-input"
            value={formatTime(timer)}
            ref={timerRef}
            onChange={(e) => handleTimeInputChange(e, setTimer)}
            onClick={() => handleTimeClick(timerRef)}
          />
          <div className="button-group">
            <button className="start-pause-button" onClick={handleStartPauseTimer} style={{ backgroundColor: '#1d1d1d' }}>
              {isTimerRunning ? 'Pause' : 'Start'}
            </button>
            <button className="refresh-button" onClick={handleRefresh}>
              <img src={RefreshIcon} alt="Refresh" className="icon" />
            </button>
          </div>
        </div>
      )}
      {type === 'pomodoro' && (
        <div className="pomodoro-container">
          <div className="phase-buttons">
            <button onClick={() => handlePhaseChange('pomodoro')} className={currentPhase === 'pomodoro' ? 'active' : ''}>Pomodoro</button>
            <button onClick={() => handlePhaseChange('shortBreak')} className={currentPhase === 'shortBreak' ? 'active' : ''}>Short Break</button>
            <button onClick={() => handlePhaseChange('longBreak')} className={currentPhase === 'longBreak' ? 'active' : ''}>Long Break</button>
          </div>
          <input
            type="text"
            className="time-display-input"
            value={
              currentPhase === 'pomodoro' ? formatTime(pomodoro) :
              currentPhase === 'shortBreak' ? formatTime(shortBreak) :
              formatTime(longBreak)
            }
            ref={pomodoroRef}
            onChange={(e) => {
              if (currentPhase === 'pomodoro') handleTimeInputChange(e, setPomodoro);
              else if (currentPhase === 'shortBreak') handleTimeInputChange(e, setShortBreak);
              else if (currentPhase === 'longBreak') handleTimeInputChange(e, setLongBreak);
            }}
            onClick={() => handleTimeClick(pomodoroRef)}
          />
          <div className="button-group">
            <button className="start-pause-button" onClick={handleStartPausePomodoro} style={{ backgroundColor: '#1d1d1d' }}>
              {isPomodoroRunning ? 'Pause' : 'Start'}
            </button>
            <button className="next-phase-button" onClick={handleNextPhase}>
              <img src={SkipIcon} alt="Next Phase" className="icon" />
            </button>
            <button className="refresh-button" onClick={handleRefresh}>
              <img src={RefreshIcon} alt="Refresh" className="icon" />
            </button>
          </div>
          <div className="completed-pomodoros">
            {Array.from({ length: completedPomodoros }).map((_, index) => (
              <span key={index} className="pomodoro-dot">•</span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeWidget;
