import React from 'react';
import { Link } from 'react-router-dom';
import './Landingpage.css';
import logoImage from './logo.png';  // Update this path to your actual logo image file
import landing1 from './LandingpageRes/landing1.png';
import landing2 from './LandingpageRes/landing2.png';
import landing3 from './LandingpageRes/landing3.png';
import landing4 from './LandingpageRes/landing4.png';



const LandingPage = () => {
  return (
    <div className="landing-page">
      <head>
        <title>Lifepop - Focus with Kpop Idols</title>
        <meta name="description" content="Focus longer and maintain your energy with music and soundscapes backed by the science of deep work. Discover different spaces and personalize your perfect focus space with Lifepop." />
        <meta name="keywords" content="Lifepop, Focus, Kpop Idols, Music, Soundscapes, Deep Work, Custom Playlists, Virtual Spaces" />
        <meta name="author" content="Lifepop" />
      </head>
      <div className="header">
        <div className="logo">
          <img src={logoImage} alt="Lifepop Logo" />
        </div>
        <div className="menu">
        <Link to="/about" className="menu-item">About</Link>
          <Link to="/explore" className="menu-item">Explore</Link>
          {/* <Link to="/feed" className="menu-item">Feed</Link> */}
          <Link to="/" className="start-button">Start focusing'</Link>
        </div>
      </div>
      <div className="main-content">
        <h1>Focus with Kpop idols</h1>
        <p>Focus longer, maintain your energy. Music and soundscapes backed by the science of deep work.</p>
        <Link to="/" className="start-button">Start focusing</Link>
        <div className="main-content">
          <img src={landing1} alt="Lifepop showcase" />
        </div>
        <div className="main-content">
          <h2>Discover</h2>
          <p>Explore hundreds of virtual spaces.</p>
          <Link to="/explore" className="start-button">Explore</Link>
          <div className="main-content">
            <img src={landing4} alt="Lifepop showcase" />
          </div>        
        </div>
        <div className="main-content">
          <h2>Select different spaces</h2>
          <p>Personalize your Perfect Focus Space</p>
          <Link to="/" className="start-button">Start focusing</Link>
          <div className="main-content">
          <img src={landing2} alt="Lifepop showcase" />
        </div>        </div>
        <div className="main-content">
          <h2>Custom Playlists</h2>
          <p>Add your favorite playlist from Spotify, YouTube, Apple Music, SoundCloud, or Amazon Music.</p>
          <Link to="/" className="start-button">Start focusing</Link>
          <div className="main-content">
          <img src={landing3} alt="Lifepop showcase" />
        </div>        </div>

      </div>
      <div className="footer">
        <p>© 2024 Lifepop. All rights reserved.</p>
      </div>
    </div>
  );
};

export default LandingPage;
