import React, { useState } from 'react';
import { Route, Routes, BrowserRouter as Router, useLocation, Link } from 'react-router-dom';
import Home from './Components/Home';
import Timer from './Components/Timer';
import Pomodoro from './Components/Pomodoro';
import NotFound from './Components/NotFound';
import Toolbar from './Components/Toolbar';
import DropdownBelow from './Components/DropdownBelow';
import Checkin from './Components/Checkin';
import About from './Components/About';
import ClockFree from './Components/ClockFree';
import ClockSignedIn from './Components/ClockSignedIn';
import Player from './Components/Player';
import Sidebar from './Components/Sidebar';
import './Components/Player.css';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ReactModal from 'react-modal';
import { EmailIcon, FacebookIcon, LinkedinIcon, PinterestIcon, RedditIcon, TelegramIcon, TwitterIcon, WhatsappIcon } from "react-share";
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, PinterestShareButton, RedditShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import fullscreenLight from "./Resources/fullscreenLight.svg";
import fullscreenDark from "./Resources/fullscreenDark.svg";
import fullscreenCloseLight from "./Resources/fullscreenCloseLight.svg";
import fullscreenCloseDark from "./Resources/fullscreenCloseDark.svg";
import colorLight from "./Resources/colorLight.svg";
import colorDark from "./Resources/colorDark.svg";
import shareLight from "./Resources/shareLight.svg";
import shareDark from "./Resources/shareDark.svg";
import Plus from './Plus';
import Free from './Free';
import Explore from './Explore';
import Signedin from './Signedin';
import SidebarRight from './Components/SidebarRight';
import './App.css';
import PomodoroSignedin from './Components/PomodoroSignedin';
import spaceUrls from './Components/spaceUrls.json';
import Spacebar from './Components/Spacebar';
import LandingPage from './Components/Landingpage';
import './Components/Landingpage.css';
import logo from './Components/logo.png'; // Make sure to use the correct path to your logo file

const AppContent = () => {
  const modeClassNames = {
    dark: 'dark',
    neon: 'neon',
    darkBlue: 'darkBlue',
    darkRed: 'darkRed',
    light: 'light',
    tan: 'tan',
    lightBlue: 'lightBlue',
    lightRed: 'lightRed',
    afterDark: 'afterDark'
  };

  const [isHidden, setIsHidden] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [volume, setVolume] = useState(0.8);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const initialId = params.get('id') || '1'; // Default to 6 if no id is provided
  const initialVideoId = spaceUrls.find(url => url.id === parseInt(initialId)).videoId;

  const [currentVideoId, setCurrentVideoId] = useState(initialVideoId);

  const handleUrlChange = (videoId) => {
    setCurrentVideoId(videoId);
  };

  const handleMuteToggle = () => {
    setIsMuted(!isMuted);
  };

  const handleVolumeChange = (volume) => {
    setVolume(volume);
    if (volume === 0) {
      setIsMuted(true);
    } else {
      setIsMuted(false);
    }
  };

  const handleModeChange = (mode) => {
    localStorage.setItem('mode', mode);
    setCurrentMode(mode);
  }

  const [currentMode, setCurrentMode] = useState("light");
  // const [currentMode, setCurrentMode] = useState(localStorage.getItem('mode') || "light");
  const handle = useFullScreenHandle();
  const selectedMode = modeClassNames[currentMode];
  const [fullScreen, setFullScreen] = useState(false);
  const toggleFullScreen = () => setFullScreen(!fullScreen);

  return (
    <FullScreen handle={handle}>
      {location.pathname === '/' && (
        <div className="topnav-container">
          <div className='logo-container' style={{ borderRadius: '12px', background: 'rgba(255, 255, 255, 0.10)', backdropFilter: 'blur(15px)', padding: '4px 10px', position: 'absolute', left: '10px' }}>
            <img src={logo} alt="Lifepop Logo" style={{ height: '40px' }} />
          </div>
          <div className={`topnav-wrapper ${selectedMode}`}>
            <div className="navi-wrapper">
              <Link to="/about" className="nav-link">About</Link>
              <Link to="/explore" className="nav-link">Explore</Link>
            </div>
            <div className='spacer-nav' />
            <div className="navi-wrapper">
              <DropdownBelow mode={selectedMode} icon={<div><Checkin /></div>} customLeft="-44px" position="below">
                <div className="level-wrapper">
                  <div className='level-header'>Your level:</div>
                  <div className="spacer-nav" />
                  <div className="level">
                    <Checkin />
                  </div>
                  <div className="level-description">
                    <i>Come back tomorrow to level up!</i>
                  </div>
                </div>
              </DropdownBelow>
            </div>
            {/* <div className='spacer' />
            <DropdownBelow mode={selectedMode} customLeft="-44px" icon={<div><img src={selectedMode === "light" || selectedMode === "tan" || selectedMode === "lightBlue" || selectedMode === "lightRed" ? colorLight : colorDark} className="icon-wrapper" /></div>} position="below">
              <div className="mode-buttons">
                <div className="mode-wrapper">
                  <div className="dark-select" onClick={() => handleModeChange('dark')}> </div>
                  <div className="spacer" />
                  <div className="neon-select" onClick={() => handleModeChange('neon')}> </div>
                  <div className="spacer" />
                  <div className="darkBlue-select" onClick={() => handleModeChange('darkBlue')}> </div>
                  <div className="spacer" />
                  <div className="darkRed-select" onClick={() => handleModeChange('darkRed')}> </div>
                </div>
                <div className="mode-wrapper">
                  <div className="light-select" onClick={() => handleModeChange('light')}> </div>
                  <div className="spacer" />
                  <div className="tan-select" onClick={() => handleModeChange('tan')}> </div>
                  <div className="spacer" />
                  <div className="lightBlue-select" onClick={() => handleModeChange('lightBlue')}> </div>
                  <div className="spacer" />
                  <div className="lightRed-select" onClick={() => handleModeChange('lightRed')}> </div>
                </div>
              </div>
            </DropdownBelow> */}
            <div className='spacer-nav' />
            <div className="navi-wrapper">
              <DropdownBelow className="navi-wrapper" mode={selectedMode} icon={<div><img src={selectedMode === "light" || selectedMode === "tan" || selectedMode === "lightBlue" || selectedMode === "lightRed" ? shareLight : shareDark} className="icon-wrapper-small" /></div>} position="below" customLeft="-130px" customHeight='125px' customWidth='280px'>
                <div className='share-wrapper'>
                  <div className='level-header'>
                    Share
                  </div>
                  <br />
                  <div>
                    <EmailShareButton
                      url={"https://lifepop.io"}
                      quote={'Lifepop'}>
                      <EmailIcon size={32} round={true} />
                    </EmailShareButton>
                    <FacebookShareButton
                      url={"https://lifepop.io"}
                      quote={'Lifepop'}>
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={"https://lifepop.io"}
                      quote={'Lifepop'}>
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                    <PinterestShareButton
                      url={"https://lifepop.io"}
                      quote={'Lifepop'}>
                      <PinterestIcon size={32} round={true} />
                    </PinterestShareButton>
                    <LinkedinShareButton
                      url={"https://lifepop.io"}
                      quote={'Lifepop'}>
                      <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                    <RedditShareButton
                      url={"https://lifepop.io"}
                      quote={'Lifepop'}>
                      <RedditIcon size={32} round={true} />
                    </RedditShareButton>
                    <TelegramShareButton
                      url={"https://lifepop.io"}
                      quote={'Lifepop'}>
                      <TelegramIcon size={32} round={true} />
                    </TelegramShareButton>
                    <WhatsappShareButton
                      url={"https://lifepop.io"}
                      quote={'Lifepop'}>
                      <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                  </div>
                </div>
              </DropdownBelow>
            </div>
            <div className='spacer' />
            <div className="top-wrapper">
              {fullScreen ? (
                <div onClick={() => { handle.exit(); toggleFullScreen() }}>
                  <img src={selectedMode === "light" || selectedMode === "tan" || selectedMode === "lightBlue" || selectedMode === "lightRed" ? fullscreenCloseLight : fullscreenCloseDark} className="icon-wrapper-small" />
                </div>
              ) : (
                <div onClick={() => { handle.enter(); toggleFullScreen() }}>
                  <img src={selectedMode === "light" || selectedMode === "tan" || selectedMode === "lightBlue" || selectedMode === "lightRed" ? fullscreenLight : fullscreenDark} className="icon-wrapper-small" />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {location.pathname === '/' && (
        <div className={`${isHidden ? 'hidden' : ''}`}>
          <Spacebar
            onUrlChange={handleUrlChange}
            isMuted={isMuted}
            onMuteToggle={handleMuteToggle}
            volume={volume}
            onVolumeChange={handleVolumeChange}
          />
          <Toolbar
            onUrlChange={handleUrlChange}
            isMuted={isMuted}
            onMuteToggle={handleMuteToggle}
            volume={volume}
            onVolumeChange={handleVolumeChange}
          />
        </div>
      )}
      <Routes>
        <Route path="/" element={<Player videoId={currentVideoId} isMuted={isMuted} volume={volume} />} />
        <Route path="/explore" element={<Explore />} />
        <Route path="/about" element={<LandingPage mode={selectedMode} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </FullScreen>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
