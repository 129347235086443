import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import spaceUrls from './Components/spaceUrls.json';
import './Explore.css';  // Updated CSS file
import logoImage from './Components/logo.png';  // Update this path to your actual logo image file

function Explore() {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const filteredUrls = spaceUrls.filter(url =>
    url.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleUrlClick = (id) => {
    window.location.href = `/?id=${id}`;
  };

  return (
    <div>
        <div className="header-explore">
        <div className="logo">
          <img src={logoImage} alt="Lifepop Logo" />
        </div>
        <div className="menu">
          <Link to="/about" className="menu-item">About</Link>
          <Link to="/explore" className="menu-item">Explore</Link>
          {/* <Link to="/feed" className="menu-item">Feed</Link> */}
          <Link to="/" className="start-button">Start focusing'</Link>
        </div>
      </div>
      <div className="explore-container">
      <h1>
          Discover Kpop spaces
          </h1>
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearchChange}
        className="search-box"
      />
      <div className="spaces-list">
        {filteredUrls.map((url) => (
          <div key={url.id} className="space-item" onClick={() => handleUrlClick(url.id)}>
            <img
              src={`https://img.youtube.com/vi/${url.videoId}/hqdefault.jpg`}
              alt={url.name}
              className="thumbnail"
            />
            <div className="space-name">{url.name}</div>
          </div>
        ))}
      </div>
    </div>
    </div>
    
  );
}

export default Explore;
